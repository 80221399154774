<template>
	<v-sheet outlined class="report-details-wrapper general-ledger" :class="isLoading ? 'is-loading-wrapper' : ''">
		<div class="loading-wrapper" v-if="isLoading">
            <v-progress-circular
                :size="40"
                color="#0171a1"
                indeterminate>
            </v-progress-circular>
        </div>

		<div v-if="!isLoading">
			<div class="report-main-header">
				<v-breadcrumbs :items="breadCrumb" class="pl-0 pt-0 px-3 pb-4 px-sm-0">
					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
							link
							:href="item.href"
							@click.prevent="$router.push(item.href)"
							:disabled="item.disabled"
						>
							{{ item.text }}
						</v-breadcrumbs-item>
					</template>
					<template v-slot:divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>
				</v-breadcrumbs>
				
				<div class="d-md-flex d-sm-flex justify-space-between align-center report-details-header">
					<div class="px-3 px-sm-0">
						<span class="report-title text-h5">
							<h2 class="report-title">{{ 'Journal Entries' }}</h2>
						</span>
					</div>
				</div>
			</div>
			<v-sheet>
				<report-search :filters="filters" @toggle="onToggleSearch"></report-search>
			</v-sheet>

			<v-card flat :loading="isLoading" class="mt-4 pa-4">
				<v-row>
					<v-col cols="12" md="12" lg="12" class="pb-0">
						<v-simple-table class="mb-12">
							<thead>
								<tr>
									<th style="width: 35%; min-width: 35%;"></th>
									<th style="width: 15%; min-width: 15%;"></th>
									<th style="width: 15%; min-width: 15%;" class="text-end"></th>
									<th style="width: 15%; min-width: 15%;" class="text-end">Debit</th>
									<th style="width: 15%; min-width: 15%;" class="text-end">Credit</th>
								</tr>
							</thead>

							<tbody v-for="report,i in reportsData" :key="i">
								<tr class="body-header">
									<td style="color: #4a4a4a;"><span class="font-bold text-xl">{{ report.date }}</span> <span class="font-semi-bold">{{ report.transaction }}</span></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
                                <tr v-for="ledger,i2 in report.ledgers" :key="i2">
                                    <td style="color: #4a4a4a;">{{ ledger.account }}</td>
									<td></td>
									<td></td>
									<td class="text-end">{{ ledger.debit ? moneyFormat(ledger.debit) : '--' }}</td>
									<td class="text-end">{{ ledger.credit ? moneyFormat(ledger.credit) : '--' }}</td>
                                </tr>
                                <tr>
									<td></td>
									<td></td>
                                    <td style="color: #4a4a4a;" class="font-semi-bold">Total</td>
									<td class="text-end font-semi-bold"> {{ report.debit_total ? moneyFormat(report.debit_total) : '--' }}</td>
									<td class="text-end font-semi-bold"> {{ report.credit_total ? moneyFormat(report.credit_total) : '--' }}</td>
                                </tr>
                                <tr style="height:70px">
                                    <td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
                                </tr>
							</tbody>
						</v-simple-table>
					</v-col>
					<v-divider class="mx-4" vertical></v-divider>
				</v-row>
			</v-card>
		</div>
	</v-sheet>
</template>

<script>
import AccountingService from '../../services/accounting.service';
import accountingGlobalMethods, { apiErrorMessage } from '../../utils/accountingGlobalMethods';
import ReportSearch from '../../components/ReportSearch.vue';

export default {
	name: 'AccountingJournalEntriesReports',
	components: {
		ReportSearch,
	},
	data() {
		return {
			data : {},
			reportsData: [],
			isLoading: false,
			isLoaded: false,
			headers: [
				{
					text: 'DATE',
					align: "start",
					sortable: false,
					value: "",
					width: "", 
					fixed: false
				},
				{
					text: 'TRANSACTION',
					align: "start",
					sortable: false,
					value: "",
					width: "", 
					fixed: false
				},
				{
					text: 'DEBIT',
					align: "end",
					sortable: false,
					value: "",
					width: "", 
					fixed: false
				},
				{
					text: 'CREDIT',
					align: "end",
					sortable: false,
					value: "",
					width: "", 
					fixed: false
				},
				{
					text: 'BALANCE',
					align: "end",
					sortable: false,
					value: "",
					width: "", 
					fixed: false
				}
			],

			filters: [
				{
					key: 'basis',
					value: 'Basis',
					operators:{
						equal: true,
						not_equal: true,
						range: false,
					},
					type: 'select',
					selected_value: 'accrual',
					selected_value_name: 'Accrual',
					selected_operator: 'equal',
					is_selected: true,
				},
				{
					key: 'report_at',
					value: 'Report Date',
					operators:{
						equal: true,
						not_equal: false,
						range: true,
					},
					type: 'date',
					selected_value: null,
					selected_value_name: null,
					selected_operator: null,
				},
				{
					key: 'de_account_id',
					value: 'Chart of Accounts',
					operators:{
						equal: true,
						not_equal: false,
						range: false,
					},
					type: 'select',
					selected_value: null,
					selected_value_name: null,
					selected_operator: null,
				},
				{
					key: 'contact',
					value: 'Contact',
					operators:{
						equal: true,
						not_equal: true,
						range: false,
					},
					type: 'select',
					selected_value: null,
					selected_value_name: null,
					selected_operator: null,
				},
				{
					key: 'year',
					value: 'Financial Year',
					operators:{
						equal: true,
						not_equal: false,
						range: false,
					},
					type: 'select',
					selected_value: '',
					selected_value_name: this.getCurrentYear,
					selected_operator: 'equal',
				},
			],

			selectedParams: [
				// 
			],
		};
	},

	computed: {

		reportId() {
			return this.$route.params.id;
		},

		breadCrumb() {
			return [
				{
					text: 'Accounting',
					disabled: true,
					href: '#'
				},
				{
					text: 'Reports',
					disabled: false,
					href: '/accounting/reports'
				},
				{
					text: this.data.name || '',
					disabled: true,
					href: '#'
				}
			];
		},

		isMobile() {
			return this.$vuetify.breakpoint.mobile;
		},
	},

	created() {
		this.loadReport();
	},

	methods: {
		...accountingGlobalMethods,

		onToggleSearch(value){
			this.loadReport({search:value});
		},

		async loadReport(searchValue = {}) {
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			try {
				const records = await AccountingService.getReport(this.reportId, searchValue );
				const report = records.data.data || {};
				let reportsData = [];
				if(report && report.class === "Modules\\Shifl\\Reports\\ShiflJournalReport"){
					const data = report.data;
					Object.keys(data).forEach(function(key){
						if(key === 'reference_documents'){
                            reportsData = data[key];
						}
					});
					this.data = report;
					this.reportsData = reportsData;
				}
				this.isLoaded = true;
			} catch (error) {
				console.log(error);
				apiErrorMessage(error);
				this.isLoaded = true;
			} finally {
				this.isLoading = false;
				this.isLoaded = true;
			}
		},
	}
};
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 767px) {
		::v-deep {
			.v-dialog .v-card .v-card__text {
				margin-bottom: 0 !important;
			}
		}
	}
</style>

<style lang="scss" scoped>
	$button-bg-color: #0171a1;

	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}

	.activity-color {
		color: #0b6084;
	}

	.activity-color.in-active-activity {
		color: #b3cfe0;
	}
</style>

<style lang="scss">
    @import '../scss/reportsDetails.scss';
</style>

